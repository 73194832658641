<template>
    <div id="app">
        <!-- 支付成功提示页面 -->
        <div class="paySuccess">
            <p>支付成功！</p>
            <span><font id="time">{{time}}</font>s 后返回...</span>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            time: 5, // 倒计时初始数
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        init(){
            var fn1 = setInterval(res=>{
            this.time--;
            if(this.time == 0){
                clearInterval(fn1);
                // window.location.go(-1);
                window.history.go(-1);
            }
        },1000)
        }
    }
}
</script>
<style scoped>
#app{
    /* line-height: 300px; */
}
.paySuccess{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    /* height: 300px; */
}
.paySuccess p{
    padding-top: 50px;
    font-size: 18px;
}
.paySuccess span{
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 50px;
    font-size: 14px;
    letter-spacing: 1px;
}
.paySuccess span font{
    font-size: 14px;
    color: #ff0000;
}
</style>